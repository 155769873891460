<template>
  <b-modal
      ref="edit-modal"
      id="composeDModal"
      size="xl"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Salva"
      cancel-title="Chiudi"
  >
    <h4 class="mb-4">Modifica Clinica</h4>
    <b-row>
      <!-- company name -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Ragione Sociale</h6>
        <b-form-input
            v-model="editedItem.company_name"
            placeholder="Inserisci la Ragione Sociale"
            name="name-group"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- email -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Email</h6>
        <b-form-input
            v-model="editedItem.email"
            placeholder="Inserisci email"
            name="name-group"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- vat number -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Partita Iva</h6>
        <b-form-input
            v-model="editedItem.vat_number"
            placeholder=""
            name="name-group"
            :maxlength="11"
            @keyup="fiscalCode"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- sdi -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>SDI</h6>
        <b-form-input
            v-model="editedItem.sdi"
            placeholder=""
            name="name-group"
            :maxlength="10"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- tel -->
      <b-col cols="12" lg="3" class="mb-3">
        <h6>Numero di telefono</h6>
        <b-form-input type="tel" v-model="editedItem.phone_number" placeholder="123 4564055"></b-form-input>
      </b-col>
      <!-- tags -->
      <b-col cols="12" lg="6" class="mb-3">
        <SelectTag ref="selectTag" @setSelect="(value) => editedItem.tags = value" />
      </b-col>
      <!-- gmaps -->
      <b-col cols="12" lg="12" class="mb-3">
        <h6>Indirizzo di residenza</h6>
        <GMapAutocomplete ref="gmaps_address" @setPlace="(value) => editedItem.address = value" />
      </b-col>
      <!-- note -->
      <b-col cols="12" lg="12" class="mb-3">
        <h6>Note</h6>
        <b-form-textarea
            id="textarea-auto-height"
            rows="3"
            max-rows="8"
            v-model="editedItem.notes"
        ></b-form-textarea>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "EditCaregiver",
  components: {
    SelectTag: () => import('@/views/tags/components/SelectTags'),
    GMapAutocomplete: () => import("@/components/gmaps/Autocomplete"),
  },
  data() {
    return {
      editedIndex: '',
      editedItem: {},
      autoCompleteOptions: {
        componentRestrictions: {
          country: ['IT'],
        },
      },
    }
  },
  methods: {
    async save() {
      const res = await this.axios.post('clinic', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    fiscalCode() {
      this.editedItem.fiscal_code = this.editedItem.fiscal_code.toUpperCase()
    },
  }
}
</script>

<style scoped>

</style>